import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { BackofficeLayoutComponent } from "./backoffice-layout.component";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavigationItemComponent } from "./sidebar/navigation-item/navigation-item.component";
import { RouterModule } from "@angular/router";

import { LanguagesModule } from "../languages/languages.module";
import { LanguagesComponent } from "../languages/languages.component";
import {
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { SidebarService } from "./_services/sidebar.service";
import { WindowRef } from "./_services/window-ref";
import { ToggleFullscreenDirective } from "../core/_directives/toggle-fullscreen.directive";
import { LanguagesService } from "../languages/languages.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        PerfectScrollbarModule,
        LanguagesModule,
    ],
    declarations: [
        BackofficeLayoutComponent,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NavigationItemComponent,
        ToggleFullscreenDirective,
        LanguagesComponent,
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        BackofficeLayoutComponent,
    ],
    providers: [
        SidebarService,
        LanguagesService,
        WindowRef,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
})
export class BackofficeLayoutModule {}
