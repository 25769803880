import {Component, ViewContainerRef} from "@angular/core";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent {
    title = "app";

    constructor() {
    }
}
