<!-- Sidebar Header starts -->
<div class="sidebar-header">
    <div class="logo clearfix">
        <a [routerLink]="['/']" class="logo-text float-left">
            <div class="logo-img">
                <svg width="35" height="35" version="1.1" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="m28.985 12.392h-6.57v-6.568a5.484 5.484 0 0 0-5.466-5.469h-0.436a5.484 5.484 0 0 0-5.465 5.469v17.53l0.405 0.406h17.532a5.482 5.482 0 0 0 5.465-5.467v-0.433a5.48 5.48 0 0 0-5.465-5.468z" fill="#ed145a" data-name="Path-symbol-red"/>
                        <path d="m22.415 26.42-14.028-14.028h-1.489l15.517 15.517v-1.489zm0 3.406-17.437-17.434h-1.485l18.922 18.922v-1.488zm0-6.81-10.625-10.624h-1.488l12.113 12.112v-1.488zm-21.6-9.895 20.831 20.833h0.768v-0.721l-20.84-20.841h-0.758l-1e-3 0.729zm21.6 6.489-7.218-7.218h-1.487l8.7 8.706 5e-3 -1.488zm0-3.406-3.814-3.812h-1.485l5.3 5.3-1e-3 -1.488zm-21.6 3.728 3.811 3.813h1.488l-5.3-5.3 1e-3 1.487zm10.244 14.022h1.856l-1.856-1.857v1.857zm11.355-21.562h-1.893l1.893 1.893v-1.893zm-21.6 11.353h1.894l-1.894-1.895v1.895zm10.244 6.432 3.775 3.777h1.486l-5.262-5.264 1e-3 1.487zm-10.242-13.651 7.22 7.219h1.486l-8.706-8.707v1.488zm10.244 10.241 7.181 7.183h1.492l-8.668-8.671-5e-3 1.488z" fill="#9e005d" data-name="Path-symbol-purple"/>
                    </g>
                </svg>
            </div>
        </a>
        <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle">
            <i
                (click)="onToggleSidebar($event, !isNavExpand)"
                [ngClass]="[isNavExpand ? 'ft-toggle-right' : 'ft-toggle-left']"
                class="toggle-icon"
                data-toggle="expanded"></i>
        </a>
        <a class="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose">
            <i (click)="onHideSidebar()" class="ft-x"></i>
        </a>
    </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div [perfectScrollbar] class="sidebar-content">
    <div class="nav-container">
        <ul class="navigation">
            <app-navigation-item
                    *ngFor="let menuItem of menuItems;"
                    [menuItem]="menuItem"></app-navigation-item>
        </ul>
    </div>
</div>
<!-- Sidebar Content Ends -->
