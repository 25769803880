import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
    imports: [CommonModule, NgbModule, MatTabsModule],
    exports: [CommonModule, TranslateModule, MatTabsModule],
})
export class LanguagesModule {}
