<div ngbDropdown placement="bottom-right">
    <a class="nav-link position-relative" id="dropdownBasic4" ngbDropdownToggle>
        <i class="ft-globe font-medium-3 blue-grey darken-4"></i>
        <p class="d-none">Languages</p>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
        <a
                class="dropdown-item py-1"
                [ngClass]="language === $languages.selected && 'active'"
                href="javascript:;"
                (click)="changeLanguage(language.code)"
                *ngFor="let language of languages">
            <span>{{ language.name }}</span>
        </a>
    </div>
</div>
