import {Component} from "@angular/core";
import {AuthService} from "../../auth/auth.service";
import {Router} from "@angular/router";
import {AccessService} from "../../core/_services/access.service";
import {SidebarService} from "../_services/sidebar.service";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})

export class NavbarComponent {
    isHideSidebar: boolean;
    toggleClass = "ft-maximize";

    constructor(private _auth: AuthService,
                private $access: AccessService,
                private router: Router,
                private _sidebarService: SidebarService) {
        this.isHideSidebar = this._sidebarService.isHideSidebar;
        this._sidebarService.isHideSidebarChange.subscribe(status => this.isHideSidebar = status);
    }

    onLogout() {
        this._auth.logout();
    }

    editProfile() {
        // let userId = this.$access.user.id;
        // this.router.navigate( [`users/${userId}`] );

    }

    ToggleClass() {
        if (this.toggleClass === "ft-maximize") {
            this.toggleClass = "ft-minimize";
        } else {
            this.toggleClass = "ft-maximize";
        }
    }

    toggleSidebar() {
        event.preventDefault();
        event.stopPropagation();
        this._sidebarService.isHideSidebarChange.next(!this.isHideSidebar);
    }
}
