import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {LoginPageComponent} from "./login/login-page.component";

const routes: Routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full"
    },
    {
        path: "login",
        component: LoginPageComponent,
    },
    {
        path: "**", redirectTo: "login"
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {
}
