import {Routes} from "@angular/router";
import {AuthGuard} from "../auth/auth.guard";

export const PageRoutes: Routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full"
    },
    {
        path: "",
        loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule),
        canLoad: [AuthGuard]
    }
];

