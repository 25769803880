import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { NotificationService } from "./notification.service";

class ApiError {
    constructor(
        public status: number,
        public type: string,
        public message: string,
        public body: any,
    ) {}
}

@Injectable()
export class ApiService {
    private url = environment.api;
    private headers = new HttpHeaders({ "Content-type": "application/json" });
    private acceptLanguage;
    private options = {
        headers: this.headers,
        params: new HttpParams(),
    };

    constructor(
        private http: HttpClient,
        private $notify: NotificationService,
    ) {}

    setOptionWithParams(params, responseType = "json") {
        return {
            headers: this.headers,
            responseType: responseType,
            params: Object.getOwnPropertyNames(params).reduce(
                (p, key) => p.set(key, params[key]),
                new HttpParams(),
            ),
        };
    }

    setPlainType() {
        return {
            headers: new HttpHeaders({
                "Accept-Language": this.acceptLanguage,
            }),
            params: new HttpParams(),
        };
    }

    setLanguage(languageCode) {
        this.options.headers = new HttpHeaders({
            "Content-type": "application/json",
            "Accept-Language": languageCode,
        });
        this.acceptLanguage = languageCode;
    }

    post(path, data, plainContentType?) {
        const options = plainContentType ? this.setPlainType() : this.options;
        return this.http
            .post(
                `${this.url + path}`,
                data.toIRI ? data.toIRI() : data,
                options,
            )
            .pipe(catchError((err) => this.handleErrors(err)))
            .toPromise();
    }

    get(path, params?, responseType?) {
        const options = params
            ? this.setOptionWithParams(params, responseType)
            : this.options;

        return this.http
            .get(`${this.url + path}`, options)
            .pipe(catchError((err) => this.handleErrors(err)))
            .toPromise();
    }

    jsonGet(path) {
        return this.http
            .get(path, this.options)
            .pipe(catchError((err) => this.handleErrors(err)))
            .toPromise();
    }

    delete(path) {
        return this.http
            .delete(`${this.url + path}`, this.options)
            .pipe(catchError((err) => this.handleErrors(err)))
            .toPromise();
    }

    put(path, data) {
        return this.http
            .put(
                `${this.url + path}`,
                data.toIRI ? data.toIRI() : data,
                this.options,
            )
            .pipe(catchError((err) => this.handleErrors(err)))
            .toPromise();
    }

    handleErrors(err) {
        switch (err.status) {
            case 400: {
                break;
            }
            case 401: {
                break;
            }
            case 403: {
                this.$notify.permissionError();
                break;
            }
            case 500: {
                break;
            }
        }
        return throwError(
            new ApiError(
                err.status,
                (err.error && err.error.type) || err.name,
                (err.error && err.error.message) || err.statusText,
                err.error,
            ),
        );
    }
}
