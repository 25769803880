import { Routes } from "@angular/router";

export const BackofficeRoutes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("app/dashboard/dashboard.module").then(
                (m) => m.DashboardModule,
            ),
    },
    {
        path: "users",
        loadChildren: () =>
            import("app/users/users.module").then((m) => m.UsersModule),
    },
    {
        path: "articles",
        loadChildren: () =>
            import("app/articles/articles.module").then(
                (m) => m.ArticlesModule,
            ),
    },
    {
        path: "article-visibility",
        loadChildren: () =>
            import("app/article-visibility/article-visibility.module").then(
                (m) => m.ArticleVisibilityModule,
            ),
    },
    {
        path: "customers",
        loadChildren: () =>
            import("app/customers/customers.module").then(
                (m) => m.CustomersModule,
            ),
    },
];
