import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
    {
        path: "/dashboard",
        title: "sidebar_menu.dashboard",
        icon: "ft-home",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        access: "*",
    },
    {
        path: "/users",
        title: "sidebar_menu.users",
        icon: "ft-users",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        access: ["ROLE_ADMIN", "ROLE_ASSISTANT"],
        submenu: [
            {
                path: "/users/create",
                title: "sidebar_menu.create_user",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
                access: "ROLE_ADMIN",
            },
        ],
    },
    {
        path: "/articles",
        title: "sidebar_menu.articles",
        icon: "ft-layers",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        access: ["ROLE_ADMIN", "ROLE_ASSISTANT"],
        submenu: [
            {
                path: "/articles/add",
                title: "sidebar_menu.add_article",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
                access: "ROLE_ADMIN",
            },
        ],
    },
    {
        path: "/article-visibility",
        title: "sidebar_menu.article_visibility",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        access: ["ROLE_ADMIN", "ROLE_ASSISTANT"],
        submenu: [
            {
                path: "/article-visibility/create",
                title: "sidebar_menu.create_new",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
                access: "ROLE_ADMIN",
            },
        ],
    },
    {
        path: "/customers",
        title: "sidebar_menu.customers",
        icon: "ft-users",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        access: ["ROLE_ADMIN", "ROLE_ASSISTANT"],
        submenu: [],
    },
];
