import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {BackofficeLayoutComponent} from "./backoffice-layout/backoffice-layout.component";
import {BackofficeRoutes} from "./backoffice-layout/backoffice.routes";
import {AuthGuard} from "./auth/auth.guard";
import {PageLayoutComponent} from "./page-layout/page-layout.component";
import {PageRoutes} from "./page-layout/page.routes";

const appRoutes: Routes = [
    {
        path: "",
        component: BackofficeLayoutComponent,
        children: BackofficeRoutes,
        canActivate: [AuthGuard]
    },
    {
        path: "",
        component: PageLayoutComponent,
        children: PageRoutes
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
