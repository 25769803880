import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Language} from "./language.model";
import {ApiService} from "../core/_services/api.service";

@Injectable()
export class LanguagesService {
    selected: Language;
    languages: Language[] = [
        new Language("nl", "nl_NL", "Dutch")
    ];
    appLanguages: Language[] = [
        new Language("nl", "nl_NL", "Dutch")
    ];

    constructor(private $translate: TranslateService, private $api: ApiService) {
        this.initLanguage();
    }

    initLanguage() {
        let storedLanguageCode = localStorage.getItem("language");
        if (storedLanguageCode) {
            this.setLanguage(storedLanguageCode);
        } else {
            this.setDefault();
        }
    }

    setDefault() {
        const defaultLanguge = "nl";
        this.$translate.setDefaultLang(defaultLanguge);
        this.setLanguage(defaultLanguge);
    }

    setLanguage(languageCode: string) {
        this.$translate.use(languageCode);
        this.selected = this.languages.find(language => language.code === languageCode);
        this.$api.setLanguage(this.selected.fullCode);
        localStorage.setItem("language", languageCode);
    }
}

